
import React, { Component } from 'react'
import Title from '../GenericComponents/Title'
import swal from 'sweetalert'
import Paginations from '../GenericComponents/Pagination'
import EmployeeActivityBars from '../Settings/EmployeeActivityBars'
import Loader from '../Loader/Loader'
import { delete_ApiManager,get_ApiManager,post_ApiManager, search_ApiManager, view_ApiManager } from '../ApiManager/ApiManager'
import ExportOptions from '../GenericComponents/ExportOptions'
import moment from 'moment'
import { Link } from 'react-router-dom'
import './clients.css'
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { Sorting } from '../GenericComponents/SortTables'
import GenericMerge from '../GenericComponents/GenericMerge'
import { activate_status, expo_branches, genders, info_message } from '../../utilis/constants'
import MultipleSearchs from '../GenericComponents/MultipleSearchs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ExternalAgentModel from '../GenericComponents/ExternalAgentModel'
import { FaEllipsisH, FaEye, FaRegCheckSquare, FaTrashAlt } from 'react-icons/fa'

class ViewClients extends Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef();
        this.scrollRef = React.createRef();
        var permissions = JSON.parse(localStorage.getItem('permissions'));
        var userDetail = JSON.parse(localStorage.getItem('user')); 
        this.state = {
            name:'', email:'', phone:'', country:'', counselor:[], superAgent: [], subAgent:[], gender:'', assignedTo:[], branch:"", event:"",
            getStartDate:'',
            sDate: null,
            eDate: null,
            getEndDate:'',
            allUsers:[],
            participants:[],
            totalItem:'',
            clientName:'',
            currentPage: 1,
            assignedID:'',
            lastPage:'',
            perPage: 10,
            open:false,
            search:'',
            days: null,
            allClients:[],
            order:'ASC',
            userRole: userDetail,
            perItem:'',
            allAgents:[],
            selectedClients:[],
            allPermissions: permissions,
            bulk: false,
            bulkIds:[],
            events:[],
            status: 2,
            duplicate:false,
            showMerge:false, 
            infoMessage:'',
            referenceBy:'',
            externalAgentModel: false,
            showStatus: 'no',
            rExternalList:[],
            pExternalList:[],
            externalRAgentType:'',
            externalPAgentType:'',
            headers: [
                {title: '#' , dataKey: "index", align: "text-center", key: 1, checked: true, canChange: false},
                {title: 'Counselor', dataKey: "counselor", align:"text-center", key: 2, checked: true, canChange: false },
                {title: 'Client' , dataKey: "name", align:'w-10', key: 3, checked: true, canChange: false},
                {title: 'Email' , dataKey: "email", align: "text-center ",  key: 4, checked: true, canChange: false},
                {title: 'Phone' , dataKey: "mobile", align: "text-center ",  key: 5, checked: true, canChange: true},
                {title: 'Country', dataKey: "country", align: "text-center ",  key: 6, checked: false, canChange: true },
                {title: 'Gender', dataKey: "gender", align: "text-center ",  key: 7, checked: false, canChange: true },
                {title: 'Processing Branch', dataKey: "superagent", align: "text-center ",  key: 8, checked: true, canChange: true},
                {title: 'Referring Branch', dataKey: "subagent", align: "text-center ",  key: 9, checked: true,canChange: true },
                {title: 'AssignedTo', dataKey: "assignedTo", align:"text-center",  key:10, checked: true, canChange: true },
                {title: 'Status', dataKey: "status", align:"text-center", key: 11, checked: false, canChange: true },
                {title: 'CreatedAt', dataKey: "created_at", align:"text-center",  key: 12, checked: false, canChange: true},
                {title: 'Event', dataKey: "event", align:"text-center", key: 13, checked: false, canChange: true },
                {title: 'Branch', dataKey: "branch", align:"text-center", key: 14, checked: false, canChange: true },
                {title: 'Active Status', dataKey: "active_status", align:"text-center", key: 15, checked: false, canChange: true },
                {title: 'Reference By', dataKey: "referred_by", align:"text-center", key: 16, checked: false, canChange: true },
                {title: '', align:'w-5', dataKey: '', key: 17, checked: true, canChange: false },
            ],
        }
        this.handleSearchCallback = this.handleSearchCallback.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if(this.state.allPermissions['clients'] === 1){
            this.getClients();
            this.getAgents();
            this.getEvents();
            this.getAllUsers();
        }
        document.addEventListener('click', this.handleClickOutside.bind(this), true);
    }
    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside.bind(this), true);
    };
    getAllUsers = () => {
        let url = 'getAllUsers';
        view_ApiManager(url, 0, 0,(res) => {
            console.log('Get all Users',res);
            const participants = res.data.data.map(user => ({
                label: user.name, // Adjust the property name based on your API response
                value: user.id,
              }));
            this.setState({allUsers: res.data.data, participants: participants})
        },(err) => {
            console.log(err);
        });
     }
     handleClickOutside(event) {
        if (this.ref.current && !this.ref.current.contains(event.target)) {
            this.props.onClickOutside && this.props.onClickOutside();
            if (!this.ref.current.contains(event.target)) {
                this.setState({ open: false });
            }
        }
    };
     handleClick= (id) =>{
        this.setState({clickedId: id});
        if(this.state.open === true) this.setState({open:false});
        else this.setState({open:true})
     }

    getClients = () => {
        this.setState({loading: true});
        let currentPage = this.state.currentPage;
        let perPage = this.state.perPage;
        let $startDate = this.state.sDate;
        let $endDate = this.state.eDate;
        let $days = this.state.days;
        let url = `getAllClients/${$days}/${$startDate}/${$endDate}/${null}/${this.state.status}/${this.state.showStatus}`;
        console.log(url);
        view_ApiManager(url, currentPage, perPage,(res) => {
            console.log('All Clients',res);
            this.setState({
                allClients: res.data.data['data'],
                loading: false,
                totalItem: res.data.data['total'],
                perPage: res.data.data['per_page'],
                lastPage: res.data.data['last_page'],
                perItem: res.data.data['data'].length,
            });
            let options = res.data.data['data'].map(function (item) {
                return { 
                  index: item.index,
                  counselor: item.counselor,
                  name: item.name,
                  email: item.email,
                  mobile: item.mobile,
                  country: item.country,
                  gender: item.gender,
                  superagent: item.superagent,
                  subagent: item.subagent,
                  assignedTo: item.assignedTo,
                  eduassigned: item.eduAssign,
                  migassign: item.migAssign,
                  status: item.ecount + item.mcount > 0 ? "Closed" :  item.ecount + item.mcount == 0 ? 'Prospect' : "Open",
                  created_at: moment(item.created_at).format('YYYY-MM-D'),
                  referenceBy: item.referred_by,
                };
              })
              this.setState({selectedClients: options})
        },(err) => {
            console.log(err);
        });
     }
      // Assign application to user
     async AssignApp(clientID){
        swal({
            title: "Are you sure?",
            text: "You want to assign this application to ("+ this.state.clientName +")", icon: "warning", buttons: true,
          }).then((willDelete) => {
            if (willDelete) {
                this.setState({loading: true});
                let url = 'assignedClient';
                const data = {
                    clientId: clientID,
                    assignedId:this.state.assignedID
                }
                post_ApiManager(data, url,(res) => {
                    console.log('Client Assigned',res);
                    this.setState({loading: false});
                    if(res.data.error) swal(" ", res.data.error, "error")
                    else {swal("Success!", res.data.message, "success"); this.getClients() } 
                },(err) => {
                    console.log(err);
                })
            }
        })
     }
     //CallBack function to get changed status name and value and call api to save results
     handleCallback = (days, startDate, endDate, status) => {
        console.log(days);
        let Status = this.state.status;
        if(status == 'Active'){
            Status = 1;
        } else if(status == 'Inactive'){
            Status = 0 ;
        } else if(status == 'All'){
            Status = 2;
        }
        this.setState({days: days, sDate: startDate, eDate: endDate, status: Status}, () => {
            if(this.state.search){
                this.handleSearchCallback('clear');
                this.setState({infoMessage :info_message});
            }
            this.getClients();
        })
    }
     // CallBack function handle pagination
     handlePaginationCallback = (i, perPage) =>{
        window.scrollTo(0,this.scrollRef);
        if(this.state.search == ''){
            if(perPage && perPage != ''){
                this.setState({ perPage : perPage, currentPage:1}, () => {  this.getClients();  }); 
            } else {this.setState({ currentPage : i}, () => { this.getClients(); }); }
        } else{
            if(perPage && perPage != ''){
                this.setState({ perPage : perPage, currentPage:1}, () => {  this.handleSearchCallback("yes");  }); 
            } else {this.setState({ currentPage : i}, () => { this.handleSearchCallback("yes"); }); }   
        }
    }

     handleChange(event, clientID) {
        this.setState({assignedID: event.target.value, clientName:event.target.options[event.target.selectedIndex].text},() => {
            this.AssignApp(clientID);
        })
     }
      // Table sorting
     sorting = (col) => {
        const sortResult = Sorting(this.state.allClients, col, this.state.order)
        this.setState({order: sortResult[0], allClients: sortResult[1]})
     }
    handleSearchCallback = (input1, input2, input3, input4, input5, input6, input7, input8, input9, input10, input11, input12) => {
        let Name, Email, Phone, Country, Counselor, SuperAgent, SubAgent, Gender, Assigned, Branch, Event, ReferenceBy;
        if(input1 === 'clear'){this.setState({name: '', email: '', phone:'', mobile:'', gender:'', country:'', counselor:[], assignedTo:[], superAgent:[], subAgent:[], sDate:null, eDate: null, days: null, branch:"", event:"", referenceBy:''},() =>  this.getClients() );
        } else if (input1 === 'yes') {
            Name = this.state.name;
            Email = this.state.email;
            Phone = this.state.phone;
            Country= this.state.country;
            Counselor = this.state.counselor;
            SuperAgent = this.state.superAgent;
            SubAgent = this.state.subAgent;
            Gender = this.state.gender;
            Assigned = this.state.assignedTo;
            Branch = this.state.branch;
            Event = this.state.event;
            ReferenceBy = this.state.referenceBy
          } else {
            Name = input1;
            Email = input2;
            Phone = input3;
            Country= input4;
            Gender = input5;
            SuperAgent = input6;
            SubAgent = input7;
            Assigned = input8;
            Counselor = input9;
            Event = input10;
            Branch = input11;
            ReferenceBy = input12;
            this.setState({search:'yes', name:Name, email: Email, phone: Phone, gender: Gender,
             counselor: Counselor, superAgent: SuperAgent, subAgent: SubAgent, branch: Branch,
              assignedTo: Assigned, branch: Branch, event: Event, referenceBy: ReferenceBy});
          }
          const data = {
            name:Name,
            email:Email,
            phone:Phone,
            country:Country,
            counselor:Counselor,
            superagent:SuperAgent,
            assignedTo: Assigned,
            subagent: SubAgent,
            sDate: this.state.sDate ? this.state.sDate : 'null',
            eDate: this.state.eDate ? this.state.eDate : 'null',
            days: this.state.days ? this.state.days : 'null',
            activeStatus: this.state.status,
            status:this.state.headers[10]['checked'],
            branch: Branch,
            event: Event,
            gender: this.state.headers[6]['checked'] ? Gender == 1 ? "Female" : 'Male' : '',
            referenceBy: ReferenceBy,
        }
        let currentPage = this.state.currentPage;
        let perPage = this.state.perPage;
        let url = `search/clients`;
        console.log(data);  
        search_ApiManager(url, data, currentPage, perPage,(res) => {
            console.log('search result',res);
            this.setState({
                allClients: res.data.data['data'],
                totalItem: res.data.data['total'],
                perPage: res.data.data['per_page'],
                lastPage: res.data.data['last_page'],
                perItem: res.data.data['data'].length,
            });
            let options = res.data.data['data'].map(function (item) {
                return { 
                index: item.index,
                counselor: item.counselor,
                name: item.name,
                email: item.email,
                mobile: item.mobile,
                country: item.country,
                gender: item.gender,
                superagent: item.superagent,
                subagent: item.subagent,
                assignedTo: item.assignedTo,
                eduassigned: item.eduAssign,
                migassign: item.migAssign,
                status: item.ecount + item.mcount > 0 ? "Closed" :  item.ecount + item.mcount == 0 ? 'Prospect' : "Open",
                created_at: moment(item.created_at).format('YYYY-MM-D'),
                referenceBy: item.referred_by
                };
            })
            this.setState({selectedClients: options})
        },(err) => {
            console.log(err);
        });
    }
      //Delete a user
     async deleteClient(delete_id, name) {
        swal({
            title: "Are you sure?",
            text: "You will not be able to recover ("+ name +")",icon: "warning", buttons: true, dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
            let url = 'clients';
            delete_ApiManager(delete_id, url,(res) => {
            console.log('User Deleted',res);
            if(res.data.error) swal(" ", res.data.error, "error")
            else {
                swal("Client ("+ name +")"+" deleted successfully", {icon: "success" });
                this.setState({loading: false, currentPage:1});
                this.getClients(); 
                this.handleNotification();
            };   
        },(err) => {
            console.log(err);
        }); }   
       });
    }
      //  Call Notification bell Counter
     handleNotification(){
        const socket = this.props.socket;
        const user = this.props.user;
        socket.emit("sendNotification", {
          senderName: user,
        });
     }

     handleColumnsCallback = (selectedColumns) => {
        if(selectedColumns[10]['checked'] == true){
            this.setState({showStatus: 'yes'}, () =>{
                this.getClients();
            })
        }
        this.setState({headers: selectedColumns})
     }
     async showDuplicateClients(){
        let url = 'getDuplicateClients';
        get_ApiManager('', url,(res) => {
            console.log('duplicate-clients',res);
            this.setState({allClients: res.data.data,
                perItem: res.data.data.length,
                totalItem: res.data.data.length,
                perPage: res.data.data.length,})
        },(err) => {
            console.log(err);
        })
     }

     async getAgents(){
        let url = 'getAgentsByOffice';
        get_ApiManager('', url,(res) => {
            console.log('agents',res);
            this.setState({allAgents: res.data.data})
        },(err) => {
            console.log(err);
        })
     }
     onBulkClick = (id, name, checked) => {
        console.log(id, checked)
        if(checked == true){
            const newState = this.state.allClients.map(obj => {
                if (obj.id == id) {
                  return {...obj, checked: true};
                }
                return obj;
            });
            this.setState({bulkIds: [...this.state.bulkIds, { id:id, name:name}], allClients: newState, bulk: true});
        } else{
            const newState = this.state.allClients.map(obj => {
                if (obj.id == id) {
                  return {...obj, checked: false};
                }
                return obj;
            });
            var array = this.state.bulkIds.filter(function(item) {return item.id !== id});
            this.setState({bulkIds: array, allClients: newState})
        }
     }

     onBulkAssigned = (assignedId) => {
        let url = 'assignedBulkClients';
        const data = {
            bulkList: this.state.bulkIds,
            assignedId: assignedId,
        }
        console.log(data);
        post_ApiManager(data, url,(res) => {
            console.log('Clients Assigned',res);
            this.setState({loading: false});
            if(res.data.error) swal(" ", res.data.error, "error")
            else {
                swal("Success!", res.data.message, "success");
                this.getClients() 
                this.setState({bulk: false, bulkIds:[]});
                this.handleNotification();
            } 
        },(err) => {
            console.log(err);
        })
     }
     onBulkDelete = () => {
        swal({
            title: "Are you sure?",
            text: "You will not be able to recover these clients",icon: "warning", buttons: true, dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                let url = 'assignedBulkDelete';
                const data = {
                    bulkList: this.state.bulkIds,
                }
                console.log(data);
                post_ApiManager(data, url,(res) => {
                    console.log('clients-deleted',res);
                    if(res.data.error) swal(" ", res.data.error, "error")
                    else {
                        swal("Clients deleted successfully", {icon: "success" });
                        this.getClients(); 
                        this.setState({bulk: false, bulkIds:[]})
                        this.handleNotification();
                    };   
                },(err) => {
                    console.log(err);
                });
            }
        }); 
     }
     onMergeClick = () => {
       this.setState({showMerge: true})
     }
     // All Bulk
     allBulk = () => {
        const newState = this.state.allClients.map(obj => {
              return {...obj, checked: true};
        });
        const array = [];
        this.state.allClients.map(x => {
            array.push({ id: x.id});
        })
        this.setState({allClients: newState, bulk: true, bulkIds: array});
     }
     // Select Bulk
     selectBulk = () => {
        const newState = this.state.allClients.map(obj => {
            return {...obj, checked: false};
        });
        this.setState({bulk: !this.state.bulk, bulkIds: [],allClients: newState});
     }
     async getEvents() {
        let url = 'getEvents';
        view_ApiManager(url, this.state.currentPage, this.state.perPage,(res) => {
            console.log('expo-list',res);
            this.setState({events: res.data.data['data']});
        },(err) => {
          console.log(err);
        });
     }
     
    handleMergeModelCallback = async(check, type, mergeTo, list) => {
        if(type == 'edit'){
            let url = 'mergeClients';
            const data = {
                mergeTo: mergeTo,
                list: list
            }
            console.log(data);
            post_ApiManager(data, url,(res) => {
                console.log('merge-clients',res);
                if(res.data.error){ swal(" ", res.data.error, "error")}
                else {
                    swal(" ", res.data.message, "success")
                    this.setState({showMerge: false, bulkIds:[]})
                    this.getClients();
                }
            },(err) => {
                console.log(err);
            })
        } else{
            this.setState({ showMerge: false});
        } 
    }

    onBulkActiveStatus = (status) => {
        let url = 'bulkClientStatus';
        const data = {
            bulkList: this.state.bulkIds,
            status: status,
        }
        console.log(data);
        post_ApiManager(data, url,(res) => {
            console.log('Clients Assigned',res);
            this.setState({loading: false});
            if(res.data.error) swal(" ", res.data.error, "error")
            else {
                swal("Success!", res.data.message, "success");
                this.getClients();
                this.setState({bulk: false, bulkIds:[]});
                this.handleNotification();
            } 
        },(err) => {
            console.log(err);
        })
     }
    handleExternalAgentCallback = () => {
        this.setState({externalAgentModel: false, bulkIds:[], bulk: false})
        if(!this.state.search){
            this.getClients();
        } else{
            this.handleSearchCallback(this.state.search)
        }
     }
    handleBranchCallback =(branch) => {
        this.setState({selectedBranch: branch}, () => {
            this.getClients();
        })
    }
  render(){
    const role = JSON.parse(localStorage.getItem('user_role'));
    let count = this.state.currentPage * this.state.perPage - this.state.perPage +1;
    const user = JSON.parse(localStorage.getItem('user_id'));
    const headers = this.state.headers;
    return(
        <>
        <Title title={'View Clients'} 
        btnCol="col-lg-6 mb-0 text-center pt-lg-1" 
        btn={true}
        showTask={true}
        taskClass={'col-lg-2 pt-1 pl-1'}
        btnText="Add Client" 
        btnClass="float-right btn btn-success"
        btnUrl="/clients/add-client" 
        parentCallback={this.handleBranchCallback}
        showBranches={true}
        branchClass={"col-lg-2 pt-1 pl-2"}
        />
        <div className="row w-100 pl-3 pr-3 mb-3">
        {this.state.allPermissions['clients'] === 1 ?
        <>
        <EmployeeActivityBars  
         days={this.state.days} 
         clients={true}
         selectedsDate={this.state.sDate}
         selectedeDate={this.state.eDate}
         selectedStatus={this.state.status == 1 ? 'Active' : this.state.status == 2 ? 'All' : 'Inactive'}
         parentCallback={this.handleCallback} />
        {this.state.infoMessage &&
        <div className="col-lg-12">
        <div className="input-icons res-size-14 warning p-3 mb-2">
            <FontAwesomeIcon className="pl-1 pr-1" icon={faInfoCircle} />
            <p className="ps-2 list-input">{this.state.infoMessage}</p>
        </div>
        </div>}
          {/* search bar */}
        <MultipleSearchs
         input1={headers[2]['checked'] && 'Search Client'}
         input1_type={"input"}
         input2={headers[3]['checked'] && 'Search Email'}
         input2_type={"input"}
         input3={headers[4]['checked'] && 'Search Phone'}
         input3_type={"input"}
         input4={headers[5]['checked'] && 'Search Country'}
         input4_type={"input"}
         input5={headers[6]['checked'] && 'Search Gender'}
         input5_type={"dropdown"}
         input5Data={genders}
         input5_multiple={"no"}
         input6={headers[7]['checked'] ? 'Search Processing': ''}
         input6_type={"dropdown"}
         input6_multiple={"yes"}
         input6Data={this.state.allAgents}
         input6_filter_by={"agents"}
         input7={headers[8]['checked'] && 'Search Referring'}
         input7_type={"dropdown"}
         input7_multiple={"yes"}
         input7Data={this.state.allAgents}
         input7_filter_by={"agents"}
         input8={headers[9]['checked'] && 'AssignedTo'}
         input8_type={"dropdown"}
         input8Data={this.state.participants}
         input8_multiple={"yes"}
         input8_filter_by={"name"}
         input8_suggestion={true}
         input9={headers[1]['checked'] && 'Counselor'}
         input9_type={"dropdown"}
         input9Data={this.state.participants}
         input9_multiple={"yes"}
         input9_filter_by={"name"}
         input9_suggestion={true}
         input10={headers[12]['checked'] && 'Select Event'}
         input10_type={"dropdown"}
         input10Data={this.state.events}
         input10_multiple={"no"}
         input11={headers[13]['checked'] && 'Select Branch'}
         input11_type={"dropdown"}
         input11Data={expo_branches}
         input11_multiple={"no"}
         onSearchChange={this.handleSearchCallback}
         onColumnsChange={this.handleColumnsCallback}
         allColumns={headers}
         />
 
        <div className='w-100 mr-2 text-right'>
        <small className='underline pointer heading-blue' onClick={() => this.allBulk()}> Select All  </small>
        <FaRegCheckSquare color='#4343ff' onClick={() => this.selectBulk()} className='underline' size={14} />
        </div>

        <div className='pb-1 d-flex pt-1 font-none' style={{backgroundColor:'#e8e8e8', borderRadius:'3px'}}>
        <div className='row w-100'>
        <div className='col-lg-1 col-4'>Prospect <span className='circles assign-circle ml-1'></span></div>
        <div className='col-lg-1 col-4'>Migrations<span className='circles mig-circle ml-1'></span></div>
        <div className='col-lg-1 ml-lg-3 col-4'>Educations<span className='circles edu-circle ml-1'></span></div>
        <div className='col-lg-1 ml-lg-3 col-4'>Open<span className='circles open-circle ml-1'></span></div>
        <div className='col-lg-1 col-4'>Closed<span className='circles close-circle ml-1'></span></div>
        </div>
   
        </div>
        {this.state.loading ? <Loader /> :
        <div id="viewclientsdiv3" ref={this.scrollRef}>
        <table id="viewclients" className={`table table-bordered bg-white table-responsive ${this.state.bulkIds[0] ? 'mb-0' : 'mb-2'}`}>
         {/* Headers */}
         <thead className="thead-dark">
         <tr>
         {headers.map(head => (
         head.checked &&
         <th id={head.dataKey} className={head.align} key={head.key} onClick={() => this.sorting(head.dataKey)}> {head.title}</th> ))}
         </tr>
         </thead>
         {/* End Headers */}
        <tbody>
          {/* <FaRegSquare className='underline pointer' onClick={()=> this.onClientBulk(value.id)} size={17} />  */}
        {this.state.allClients.map(value => (
        <tr key={value.id}>
        {headers[0]['checked'] && <td className='text-center'>{this.state.bulk === true ? 
        <input type='checkbox' checked={value.checked} onClick={(e) => this.onBulkClick(value.id, value.name, e.target.checked)} /> 
        : count++}</td>}
        {headers[1]['checked'] && <td className='text-center name-text w-15'>{value.userid === 0 ? 'Manual Entry' : value.counselor}</td>}
        {headers[2]['checked'] && <td><Link className='w-15' to={`/clients/view-client-detail/${value.id}`}>{value.name}</Link></td>}
        {headers[3]['checked'] && <td className='text-center'>{value.email ? value.email : '--'}</td>}
        {headers[4]['checked'] && <td className='text-center'>{value.mobile ? value.mobile : '--'}</td>}
        {headers[5]['checked'] && <td className='text-center'>{value.country}</td>}
        {headers[6]['checked'] && <td className='text-center'>{value.gender}</td>}
        {headers[7]['checked'] && <td className='text-center'>{value.superagent ? value.superagent : '--'}</td>}
        {headers[8]['checked'] && <td className='text-center'>{value.subagent ? value.subagent : '--'}</td>}
        {headers[9]['checked'] && <td className='text-center'>
        {value.assigned == 0 ?
        <div className='size-14'>Unassigned</div>
        :
        <>
        {this.state.allUsers.map(v => 
        v.id == value.assigned ? 
        <div key={v.id} className='size-14'><span className='circles assign-circle'></span>{v.name}</div>: '')}
        {this.state.allUsers.map(v => 
        v.id == value.eduassign ? 
        <div key={v.id} className='size-14'><span className='circles edu-circle'></span>{v.name}</div> :'')}
        {this.state.allUsers.map(v => 
        v.id == value.migassign ? 
        <div key={v.id} className='size-14'><span className='circles mig-circle'></span>{v.name}</div> :'')}
        </>}
        </td>}
        {headers[10]['checked'] && 
        <td> {value.educationcount > 0 &&
        <span className='circles edu-circle'></span>
        }
        {value.migrationcount > 0 &&
        <span className='circles mig-circle'></span>
        }
        {((value.educationcount <= 0 || value.educationcount == null) 
        && (value.migrationcount <= 0 || value.migrationcount == null)) ?
        <>
        {(value.ecount + value.mcount) > 0 &&
        <> <span className='circles close-circle'> </span>Closed</> }
        {value.ecount + value.mcount == 0 && 
        <><span className='circles assign-circle'> </span>Prospect</>}
        </>
        :
        'Open (' + (value.ecount + value.mcount) + ')' }
        </td>}
    
        {headers[11]['checked'] && 
        <td className='text-center w-15'>{moment(value.created_at).format('D MMMM YYYY')}</td> }
        {headers[12]['checked'] && 
        <td className='text-center'>{value.event}</td>}
        {headers[13]['checked'] && 
        <td className='text-center'>{value.branch}</td>}
        {headers[14]['checked'] && 
        <td className='text-center'>
        {value.active_status == 0 ? 'Deactivated' : 'Activated'}
        </td>}
        {headers[15]['checked'] && <td className="text-center">{value.referred_by}</td>}
        {headers[16]['checked'] && 
        <td className='text-center'>
        <span className="wrapper icon-ref ml-0">
        <span className="dot-icon">
        <a className="a-ref-icon" onClick ={() => this.handleClick(value.id)}>
        <FaEllipsisH size={12} color={'rgb(107 107 107 / 71%)'} />
        </a>
        {this.state.open && this.state.clickedId === value.id &&
        <div className="actions-dots-dropdown">
        {this.state.userRole['role'] === 2 &&
        <button onClick ={() => this.deleteClient(value.id, value.name)}  
        className="btn btn-danger pl-3 pr-3 p-0 m-1 mb-2"> 
        <FaTrashAlt size={12} color={'#fffcfc'} /> </button>}
        <br/>
        <Link to={`/clients/view-client-detail/${value.id}`}>
        <button className="btn btn-info pl-3 pr-3 p-0 m-1 mb-2"> 
        <FaEye size={12} color={'#fffcfc'} /> </button></Link>
        </div>} </span>
        </span>
        </td>}
        </tr>
        ))}
        </tbody>
        </table>
        {this.state.bulk == true && this.state.bulkIds[0] &&
        <div className='d-flex mb-2 bg-white p-2 border'>
        <select className="form-select app-form col-2" name="assignedID" 
        onChange={(e) => this.onBulkAssigned(e.target.value)} >
        <option value='0'>Assign Selected </option>
        <option value='0' disabled>Unassigned</option>
        {[].concat(this.state.allUsers).sort((a, b) => a.name > b.name ? 1 : -1).map(value =>
        <option key={value.id} className="light" value={value.id}>{value.name}</option> )}
        </select> 

        <select className="form-select app-form col-2 ml-2" name="assignedID" 
        onChange={(e) => this.onBulkActiveStatus(e.target.value)} >
        <option value='0' disabled selected>Select Status</option>
        {activate_status.map(value =>
        <option key={value.title} className="light" value={value.title}>{value.title}</option> )}
        </select>

        {this.state.userRole['role'] === 2 &&
        <button className='btn btn-danger ml-2' onClick={() => this.onBulkDelete()}>Delete Selected</button>}

        {this.state.bulkIds[1] && 
        <button className='btn btn-info ml-2' onClick={() => this.onMergeClick()}>Merge Selected</button>}

        {this.state.bulkIds[1] && 
        <button className="btn btn-success ml-2" onClick={() => this.setState({externalAgentModel:true})}>Handle Agents</button>}
        </div>}
        </div>}
        <Paginations
         currentPage= {this.state.currentPage}
         total= {this.state.totalItem} 
         itemsPerPage= {this.state.perPage}
         lastPage={this.state.lastPage}
         totalItems={this.state.totalItem}
         perItem={this.state.perItem}
         onPageChange= {this.handlePaginationCallback} 
        />
        <GenericMerge 
        show={this.state.showMerge}
        title={"Merge Clients"}
        list={this.state.bulkIds}
        parentCallback={this.handleMergeModelCallback}
        />
         <ExternalAgentModel
         show={this.state.externalAgentModel}
         app={'clients'}
         bulkList={this.state.bulkIds}
         allAgents={this.state.allAgents}
         parentCallback={this.handleExternalAgentCallback}
         />
         {/* Exports */}
         {(role === 2 || user === 18) &&
         <div className="d-flex mb-5">
         <ExportOptions
         pdffilename={'Clients.pdf'} 
         csvfilename={'Clients.csv'} 
         xlsxfilename={'Clients'} 
         header="Clients List"
         id="viewclientsdiv3" 
         columns={headers}
         data={this.state.selectedClients} />
         </div>}
        {/* End Exports */}
        </>: 'You dont have permission to access this pages!'}
        </div>
        </>
    )
  }
}
export default ViewClients;
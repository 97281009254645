
import React ,{useState,useEffect, useRef}from "react"
import Title from '../GenericComponents/Title'
import { Sorting } from "../GenericComponents/SortTables";
import { getInternalNotes } from "../Services/apiServices";
import swal from 'sweetalert';
import Paginations from "../GenericComponents/Pagination";
import moment from "moment";
import { Link } from "react-router-dom";

const ViewInternalNotes = (props) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [lastPage, setLastPage] = useState('');
    const [totalItem, setTotalItem] = useState('');
    const [perItem, setPerItem] = useState('');
    const [noteIndex, setNoteIndex] = useState('');
    const [allNotes, setAllNotes] = useState([]);
    const [order, setOrder] = useState("ASC");
    const [headers, setHeaders] = useState([ 
        {title: '#' , dataKey: "", align: "text-center", key:1, checked: true, canChange: false},
        {title: 'Scheduler' , dataKey: "manager", align: "text-center", key:2, checked: true, canChange: false},
        {title: 'Agenda', dataKey: "agenda" , align:"text-center", key:3, checked: true, canChange: false},
        {title: 'Participants', dataKey: "participants" , align:"text-center", key:4, checked: true, canChange: false},
        {title: 'Meeting Date', dataKey: "meeting_date" , align:"text-center", key:5, checked: true, canChange: false},
        {title: 'Created At', dataKey: "created_at" , align:"text-center", key:6, checked: true, canChange: false},
        {title: '', dataKey: "" , align:"text-center", key:7, checked: true, canChange: false},
    ]);
    


    useEffect( () => {
        internalNotes();
    }, []);

    const internalNotes = async () => {
        let result = await getInternalNotes({currentPage, perPage});
        console.log('internal-notes', result)
        if (result.error) {
            swal({ text: result.error, icon: "warning", dangerMode: true });
        } else {
            setAllNotes(result.data.data.data);
            setNoteIndex(currentPage * perPage - perPage + 1);
            setPerPage(result.data.data.data['per_page']);
            setLastPage(result.data.data.data['last_page']);
            setTotalItem(result.data.data.data['total']);
            setPerItem(result.data.data.data.length);
        }
    }

    const sorting = (col) => {
        const sortResult = Sorting(allNotes, col, order)
        setOrder(sortResult[0]);
        setAllNotes(sortResult[1]);
    }
    const handlePaginationCallback = (i, perPage) => {
        if(perPage && perPage != ''){
            setCurrentPage(1);
            setPerPage(perPage); 
        } else { setCurrentPage(i);  }
    }

    return(
        <>
        <Title title={'Internal Notes'} back={true} titleCol={'col-lg-9'} 
         addMeeting={true} meetingClass={'col-lg-3 '}/>
        <div className="row w-100 px-3 mb-5">

        <div className='w-100 mr-2 text-right mt-4'>
         <table id="alledu" className="table table-bordered bg-white ">
         <thead className="thead-dark">
         <tr>
         {headers.map(head => (
            head.checked &&
            <th id={head.dataKey} className={head.align} onClick={() => sorting(head.dataKey)} key={head.key}>
            {head.title}</th>
            ))}
         </tr>
         </thead>
         <tbody>
         {allNotes.map((value, index) =>
            <tr key={index++}>
            <td className="text-center">{noteIndex + index}</td>
            <td className="text-center">{value.username}</td>
            <td className="text-center">{value.agenda}</td>
            <td className="text-center">
            {value.participants.map((v, index) => (
                <React.Fragment key={index}>
                {v}
                <br />
                </React.Fragment>
            ))}
            </td>
            <td className="text-center">
            {moment(value.meeting_date).format('D MMM YYYY')}
            <br/>{value.start_time} - {value.end_time}
            </td>
            <td className="text-center">{moment(value.created_at).format('D MMM YYYY')}</td>
            <td className="text-center">
            <Link to={`/internal-note-detail/${value.id}`}>
            <button className="btn btn-primary">View Details</button>
            </Link></td>
            </tr>
         )}
         </tbody>
         </table>
        </div>
        <Paginations
         currentPage={currentPage}
         total= {totalItem}
         itemsPerPage= {perPage}
         lastPage={lastPage}
         totalItems={totalItem}
         perItem={perItem}
         onPageChange= {handlePaginationCallback} 
        />
        </div>
        </>
    )
}
export default ViewInternalNotes
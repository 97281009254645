import React, { useState,useEffect, Suspense } from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import { Routes, Route, Navigate, useLocation, useParams} from 'react-router-dom'
import {useNavigate} from "react-router-dom"
import { io } from 'socket.io-client'
import Bell from './assets/BellIcon.mp3'
import AlertBell from './assets/alertbell.wav'
import { FaInbox, FaBell, FaRocketchat, FaBullhorn, FaUserCircle } from 'react-icons/fa';
import { getUser} from './utilis/Common'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import swal from 'sweetalert'
import Swal from 'sweetalert2'
import { get_ApiManager, post_ApiManager } from './components/ApiManager/ApiManager'
import Footer from './components/Header/Footer'
import Dashboard from './components/Dashboard/dashboard'
import Login from './components/Login/login'
import PrivateRoute from './utilis/PrivateRoute'
import Sidebar from './components/Sidebar/Sidebar'
import NotificationBar from './components/Notification/notification'
import NavItem from './components/Header/NavItem'
import ViewAccounts from './components/Accounts/ViewAccounts'
import AddAccount from './components/Accounts/AddAccount'
import AddAgent from './components/Agents/AddAgent'
import ViewAgents from './components/Agents/ViewAgents'
import ViewAppointments from './components/Appointments/ViewAppointments'
import AddAppointment from './components/Appointments/AddAppointment'
import MyAppointments from './components/Appointments/MyAppointments'
import ViewClients from './components/Clients/ViewClients'
import AddClient from './components/Clients/AddClient'
import MyClients from './components/Clients/MyClients'
import ViewLeads from './components/Clients/ViewLeads'
import Reports from './components/Clients/Reports'
import Counseling from './components/Counseling/counseling'
import ManageBranch from './components/Branches/manageBranch'
import MainApplications from  './components/Applications/MainApplications'
import ProviderSetting from './components/Providers/providerSetting'
import AddProvider from './components/Providers/addProviders'
import ViewProviders from './components/Providers/viewProviders'
import ViewCost from './components/Finance/ViewCost'
import PreviewInvoice from './components/GeneratePDF/PreviewInvoice'
import AssignClients from './components/Clients/AssignClients'
import ExpoAugSep from './components/Expo2022/ExpoAugSep'
import MainEvent from './components/Expo2022/MainEvent'
import Attendence from './components/HRManagement/Attendence'
import ApplyLeave from './components/Leave/ApplyLeave'
import ManageLeaves from './components/HRManagement/ManageLeaves'
import moment from 'moment';
import ViewCommissions from './components/Finance/viewCommissions'
import AlertDetail from './components/Notification/AlertDetail';
import Alerts from './components/Notification/Alerts'
import Chat from './components/Login/Chat/Chat';
import Inbox from './components/Inbox/inbox';
import HandleInbox from './components/Inbox/handleInbox';
import ViewPriority from './components/Applications/Priority';
import UserRoles from './components/HRManagement/UserRoles';
import DailyTask from './components/DailyReport/dailyReport';
import GrantRolePermission from './components/Permission/GrantRolePermission';
import AllDailyReport from './components/HRManagement/AllDailyReport';
import UserReportHistory from './components/HRManagement/UserReportHistory';
import ViewReport from './components/HRManagement/ViewReport';
import ExternalApps from './components/Applications/ExternalApps';
import ExternalClients from './components/Clients/ExternalClients';
import ViewInternalNotes from './components/MeetingNotes/viewInternalNotes';
import InternalNoteDetail from './components/MeetingNotes/InternalNoteDetail';
import { getChatNotifications, getInternalNotes, getMarkAsSeen, getUnSeen, getUnSeenAlert } from './components/Services/apiServices';


function App() {
  // Routes
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const EditAccount  = React.lazy(() => import('./components/Accounts/EditAccount'));
  const EditAgent  = React.lazy(() => import('./components/Agents/EditAgent'));
  const Permissions  = React.lazy(() => import('./components/Permission/permissions'));
  const Profile  = React.lazy(() => import('./components/Profile/Profile'));
  const ViewAllStatus  = React.lazy(() => import('./components/Dashboard/viewAllStatus'));
  const CountryClients  = React.lazy(() => import('./components/Clients/countryClients'));
  const ShowAllNotification  = React.lazy(() => import('./components/Notification/ShowAllNotification'));
  const ViewActivityDetail  = React.lazy(() => import('./components/Settings/ViewActivityDetail'));
  const AllMigrationApps  = React.lazy(() => import('./components/Applications/AllMigrationApps'));
  const AllEducationApps  = React.lazy(() => import('./components/Applications/AllEducationApps'));
  const EditAppointment  = React.lazy(() => import('./components/Appointments/EditAppointment'));
  const AssignEducationApp  = React.lazy(() => import('./components/Applications/AssignApplication/AssignEducation'));
  const AssignMigrationsApp  = React.lazy(() => import('./components/Applications/AssignApplication/AssignMigration'));
  const MyMigrationsApp  = React.lazy(() => import('./components/Applications/MyApplications/MigrationApplications'));
  const MyEducationApp  = React.lazy(() => import('./components/Applications/MyApplications/EducationApplications'));
  const ViewMigrationAppDetail  = React.lazy(() => import('./components/Applications/ViewMigrationDetail'));
  const ViewEducationAppDetail  = React.lazy(() => import('./components/Applications/ViewEducationDetail'));
  const ViewPreChecklistDetails  = React.lazy(() => import('./components/Checklist/viewPreChecklistDetails'));
  const AddChecklistCategory  = React.lazy(() => import('./components/Checklist/addChecklistCategory'));
  const CreateMigApp  = React.lazy(() => import('./components/Applications/CreateMigApp'));
  const CreateEduApp  = React.lazy(() => import('./components/Applications/CreateEduApp'));
  const ViewClientDetail  = React.lazy(() => import('./components/Clients/ViewClientDetail'));
  const ViewClientProfile  = React.lazy(() => import('./components/Clients/ClientProfile'));
  const UserClients  = React.lazy(() => import('./components/Clients/viewUserClients'));
  const EditCourse  = React.lazy(() => import('./components/Providers/editCourse'));
  const ViewClientReport  = React.lazy(() => import('./components/Settings/viewClientReport'));
  const SendCheckList = React.lazy(() => import('./components/Checklist/sendChecklist'));
  const ProviderDetail  = React.lazy(() => import('./components/Providers/providerDetail'));
  const ViewIntakes  = React.lazy(() => import('./components/Providers/ViewIntakes'));
  const EditProvider  = React.lazy(() => import('./components/Providers/editProvider'));
  const ViewLevels  = React.lazy(() => import('./components/Providers/viewLevels'));
  const AddCourse  = React.lazy(() => import('./components/Providers/addCourse'));
  const ViewChecklists  = React.lazy(() => import('./components/Checklist/viewChecklists'));
  const AddPreCheckList  = React.lazy(() => import('./components/Checklist/addPreChecklist'));
  const MainSetttings  = React.lazy(() => import('./components/Settings/Main'));
  const MainChecklist  = React.lazy(() => import('./components/Checklist/mainChecklist'));
  const ViewPreChecklists  = React.lazy(() => import('./components/Checklist/viewPreChecklist'));
  const ViewStatusDetail  = React.lazy(() => import('./components/Dashboard/viewStatusDetail'));
  const Logout  = React.lazy(() => import('./components/Header/Logout'));
  const EditInvoice = React.lazy(() => import('./components/Finance/Invoice/EditInvoice'))
  const ViewAllFollowups = React.lazy(() => import('./components/Dashboard/viewAllFollowups'))
  const StatusFollowup = React.lazy(() => import('./components/Dashboard/StatusFollowup'))
  const ExpoDetail = React.lazy(() => import('./components/Expo2022/ExpoDetail'))
  const ConsultantList = React.lazy(() => import('./components/Expo2022/ConsultantList'))
  const AttendenceDetail = React.lazy(() => import('./components/HRManagement/AttendenceDetail'))
  const EditAttendence = React.lazy(() => import('./components/HRManagement/EditAttendence'))
  const CreateEvent = React.lazy(() => import('./components/Expo2022/CreateEvent'))
  const ExpoFollowup = React.lazy(() => import('./components/Expo2022/ExpoFollowup'))
  const AddAlerts = React.lazy(() => import('./components/HRManagement/AddAlerts'))
  const CreateAlerts = React.lazy(() => import('./components/HRManagement/CreateAlerts'))
  const HandleChat = React.lazy(() => import('./components/Login/Chat/HandleChat'))

  // End Routes

  const [profileImage, setProfileImage] = useState(null);
  const location = useLocation();
  const history = useNavigate();
  const user = useState(JSON.parse(localStorage.getItem('user_id')));
  const [loggedInUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [ip, setIP] = useState([null]);
  const [socket, setSocket] = useState(null); 
  const {Howl} = require('howler');
  const [notifications, setNotifications] = useState([]);
  const [seenStatus, setSeenStatus] = useState(null);
  const [inboxSeen, setInboxSeen] = useState(null);
  const [seenAlert, setSeenAlert] = useState(null);
  const [activeUsers, setActiveUsers] = useState([]);
  const [attendance, setAttendance] = useState(true); 
  const [chatNotiication, setChatNotification] = useState(0);

  useEffect(() => {
    checkToken();
    // Check the token status every 10 minutes
    const tokenCheckInterval = setInterval(checkToken, 10 * 60 * 1000);
    // Clean up the interval when the component unmounts
    return () => clearInterval(tokenCheckInterval);
    // setSocket(io("127.0.0.1:3002"));
   },[]);

   useEffect(() => {
    // Example: Setting up a socket connection
    const newSocket = io("https://sockets.aptitudemigration.com.au:3002/");
    setSocket(newSocket);
    // Cleanup on unmount
    return () => newSocket.disconnect();
  }, []);

   useEffect(() => {
     if(seenStatus === null && window.location.pathname != '/login'){
       getSeenStatus(1);
       getSeenAlert(0,1);
     }
     if(window.location.pathname != '/login'){
      setTimeout(() => {
        if(attendance && loggedInUser['id'] != 11){
          attendanceStatus();
        }
      }, 20000);
      getInboxSeen();
      getChatNotification();
    }
    var i = getUser();
    if(i && i['profile_image_url'] ){
      setProfileImage(i['profile_image_url']);}
   },[]);

   useEffect(() => {
    if(localStorage.getItem('token')){
      var user = getUser();
      socket?.emit("newUser", user['name'], user['profile_image_url']);
    }
  }, [socket, user]);

  const getInboxSeen = async (status) => {
    let result = await getInternalNotes();
    console.log('inbox-unseen',result);
    if (result.error) {
      swal({ text: result.error, icon: "warning", dangerMode: true });
  } else {
      if(status === 2){
        playBellIcon();
      }
      setInboxSeen(result.data.data);
    }
  }

   const getip = async () => {
    fetch("https://geolocation-db.com/json/")
        .then(res=> res.json())
        .then(data => setIP(data))
        .catch(e => console.log(e)); 
    }
  
   const CheckedIn = () => {
    let url = 'attendanceCheckIn';
    const data = {
        date: moment().format("YYYY-MM-DD"),
        time: moment().format("YYYY-MM-DD HH:mm:ss"), 
        ip:  ip.IPv4+', '+ip.city+', '+ ip.country_name + ', '+ip.country_code,
    }
    console.log(data);
    post_ApiManager(data, url, (res) => {
        console.log('CheckedIn',res);
        if(res.data.error) swal(" ", res.data.error, "error")
        else{ swal("Attendance Marked", 'Checked-in successfully!', "success") }
    },(err) => {
        console.log(err);
    });  
   }

   const attendanceStatus = () => {
    let url = `attendanceCheck`;
    get_ApiManager('', url,(res) => {
        console.log('attendance-check',res);
        setAttendance(false)
        if(res.data.data['out']){
          Swal.fire({
            title: '<strong>Attendance</strong>',
            icon: 'info',
            html:
              'You have not marked your attendance.<b>Please check-in!</b>, ',
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonText: 'Check-In',
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {  getip(); CheckedIn() }
          })
        } else if(res.data.data['in'] && !res.data.data['out']){
          let now = moment().format("DD/MM/YYYY HH:mm:s");
          let In =  moment(res.data.data['in']).format("DD/MM/YYYY HH:mm:s");
          var total = moment.utc(moment(now,"DD/MM/YYYY HH:mm:ss").diff(moment(In,"DD/MM/YYYY HH:mm:ss"))).format("H");
          if(total > 9){
          Swal.fire({
            title: '<strong>Attendance</strong>',
            icon: 'info',
            html:
              'You are checked-in since last ' + total+' Hours <b>Please check-out!</b>, ',
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            // confirmButtonText: 'Check-Out',
            })
          }
        }
    },(err) => {
        console.log(err);
    })
  }

  useEffect(() => {  
    console.log(socket,'from app.js');
    socket?.emit("getUsers");
    if(window.location.pathname != '/login'){
      socket?.on('activeUsers', users => {
        setActiveUsers(users);
        console.log(users);
        });
      }

    socket?.on("getNotification", (data) => {
      setNotifications((prev) => [...prev, data]);
      getSeenStatus(2);
    });

    socket?.on("emailSentNotification", () => {
      console.log('sendsendNotification');
      getChatNotification();
    });
    
    socket?.on("getAlerts", (data) => {
      console.log('socket-data', data.alert_id);
      getSeenAlert(data.alert_id, 2);
    });

    socket?.on("getSeenAlert", (data) => {
      console.log('socket-data', data.alert_id);
      getSeenAlert(data.alert_id, 1);
    });

    socket?.on("getChatNotification", () => {
      console.log('chat-notification');
      getChatNotification();
    });

    socket?.on("disconnect", users => {
      setActiveUsers(users);
    });
    
    socket?.on("getNotify", () => {
      markAsSeen();
    });

   }, [socket]);

    //Call Unseen Notification Api
    const checkToken = async () => {
      const token = localStorage.getItem('token');
       if (token) {
        let url = 'checkToken';
        get_ApiManager('', url,(res) => {
          console.log('checkToken',res);
          if(res.data.auth === false){
            setIsLoggedIn(false);
            localStorage.clear();
            if(location.pathname !== '/login'){
              swal({title: "Session Expired",text: 'Please log in again!', icon: "warning", type: "error", timer: 1000})
              history('/login');
            }
          } else{
            setIsLoggedIn(true);
          }
        },(err) => {
            console.log(err);
        });
       } else {
        setIsLoggedIn(false);
      }
    }
   //Call Unseen Notification Api
   const getSeenStatus = async (status) => {
    let result = await getUnSeen();
    console.log('unseen',result);
      if(result.data['allowed'] == 'allowed'){
        setSeenStatus(result.data.data['seenstatus']);
        if(status === 2){
          playBellIcon();
        }
      }
   }
     //Call Unseen Notification Api
    const getSeenAlert = async (id, status) => {
      let result = await getUnSeenAlert(id);
      console.log('unseen-alerts',result);
        setSeenAlert(result.data.data['count']);
        if(result.data.data['allowed'][0] && status === 2){
          playAlertBellIcon();
          toast("New Alert message is here. Please check your alertbox!");
        }
   }
   const playBellIcon = () => {
    console.log('i m played');
    var sound = new Howl({src: Bell, volume: 1,});
    sound.play();
   }

   const playAlertBellIcon = () => {
    console.log('alert bell');
    var sound = new Howl({src: AlertBell, volume: 1,});
    sound.play();
   }

   const markAsSeen = async () => {
    let result = await getMarkAsSeen();
    console.log('MarkAsRead',result);
    getSeenStatus(1);
  }
   const getChatNotification = async () => {
    let result = await getChatNotifications();
    console.log('chat-count',result);
    setChatNotification(result.data.data)
   }

  return (
    <div className="App conatiner-fluid">
    <ToastContainer />
     <>
     <Suspense fallback={''}>
      <Routes>
        <Route
          path="/login"
          element={localStorage.getItem('token') ? <Navigate to="/dashboard"/> : <Login socket={socket} user={user}  />}
        />
         <Route
          path="/"
          element={localStorage.getItem('token') ? <Navigate to="/dashboard"/> : <Login  socket={socket} user={user} />}
        />
        <Route exact path="/logout" element={<Logout />} />
        <Route path="/preview/invoice" element={<PreviewInvoice />} /> 
        </Routes>
        </Suspense>
          {/* Header */}
          {isLoggedIn ? 
          <>
          {location.pathname !== '/preview/invoice' && window.location.pathname !== '/login' &&
          <nav className="navbar w-100">
          <ul className="navbar-nav col-2 w-100">

           <Inbox inboxSeen={chatNotiication} socket={socket} user={user} 
            icon={<FaInbox size={20} />} />
          
            <NotificationBar count={seenStatus} socket={socket} user={user} 
            icon={<FaBell size={50}/>}/>
            
            <Chat activeUsers={chatNotiication} socket={socket} user={user} 
            icon={<FaRocketchat />}
            />
            
            <Alerts alertCount={seenAlert} socket={socket} user={user} 
            icon={<FaBullhorn/>}
            />
            <NavItem icon={profileImage ? 
            <img className="profile-icon" src={profileImage} /> : 
            <FaUserCircle size={30} />} />
          </ul>
          </nav>}</>:''}
          {/* End Header */}
          <div className="row w-100">
          {location.pathname !== '/login' && <Sidebar/>}
          <div id="content-width" className="content-width" >
          <Suspense fallback={''}>
          <Routes>
          
          <Route path="/:pageName" element={localStorage.getItem('token') ? <PageNotFound /> : <Login socket={socket} user={user} />} />
          <Route element={<PrivateRoute/>}>
          <Route exact path="dashboard" element={<Dashboard socket={socket} user={user} />} />
          <Route exact path="/view-status-detail/:apptype" element={<ViewStatusDetail  />} />
          <Route exact path="/view-all-followups/:type" element={<ViewAllFollowups  />} />
          <Route exact path="/view-priority/:type" element={<ViewPriority />} />
          <Route exact path="/view-all/:appType" element={ <ViewAllStatus />} />
          <Route exact path="/status-followup/:ftype/:type/:status" element={ <StatusFollowup socket={socket} user={user} />} />
          <Route exact path="/my-profile" element={ <Profile />} />
          <Route exact path="/permissions" element={ <Permissions />} />
          <Route exact path="/permissions/user-role" element={ <UserRoles />} />
          {/* Manage Accounts */}
          <Route exact path="/accounts/add-account" element={<AddAccount />} />
          <Route exact path="/accounts/view-accounts" element={ <ViewAccounts />} />
          <Route exact path="/accounts/edit-account/:view_id/:edit" element={<EditAccount />} />
          {/* Manage Agents */}
          <Route exact path="/agents/view-agents" element={<ViewAgents />} />
          <Route exact path="/agents/add-agent" element={<AddAgent />} />
          <Route exact path="/agents/view-agent/:view_id/:edit" element={<EditAgent/>} />
          <Route exact path="/agents/edit-agent/:view_id/:edit" element={<EditAgent /> }/>
           {/* Notifications */}
           <Route exact path="/notifications/view-all" element={<ShowAllNotification />} />
          {/* Settings */}
          <Route exact path="/main-settings" element={<MainSetttings />} />
          <Route exact path="/view-activity-detail/:id/:days/:startDate/:endDate" element={<ViewActivityDetail />} />
          <Route exact path="/view-client-report/:id" element={<ViewClientReport />} />
          {/* Appointments */}
          <Route exact path="/appointments/view-appointments" element={<ViewAppointments />} />
          <Route exact path={`/appointments/add-appointment/:clientid`} element={<AddAppointment socket={socket} user={user} />} />
          <Route exact path="/appointments/view-my-appointments" element={<MyAppointments />} />
          <Route exact path="/appointments/edit-appointment" element={<EditAppointment />} />
          {/* All Migration Application */}
          <Route exact path="/applications/all-migration-applications/:statusid/:apptype/:migtype" element={<AllMigrationApps socket={socket} user={user}  />} />
          <Route exact path="/applications/all-education-applications/:statusid/:apptype/:edutype" element={<AllEducationApps socket={socket} user={user}  />} />
          {/* My Applications */}
          <Route exact path="/applications/main-applications" element={<MainApplications />} />
          <Route exact path="/applications/my-migrations-applications/:id" element={<MyMigrationsApp socket={socket} user={user} />} />
          <Route exact path="/applications/my-education-applications/:id" element={<MyEducationApp socket={socket} user={user} />} />
          {/* Assign Applications */}
          <Route exact  path="/applications/assign-applications" element={<MainApplications />} />
          <Route exact  path="/applications/assign-migrations" element={<AssignMigrationsApp socket={socket} user={user} />} />
          <Route exact  path="/applications/assign-education" element={<AssignEducationApp socket={socket} user={user} />} />
          <Route exact  path="/applications/view-migration-application-detail/:appid" element={<ViewMigrationAppDetail socket={socket} user={user} />} />
          <Route exact  path="/applications/view-education-application-detail/:appid" element={<ViewEducationAppDetail socket={socket} user={user} />} />
          <Route exact  path="/applications/create-migration-application/:clientid/:edit" element={<CreateMigApp socket={socket} user={user} />} />
          <Route exact  path="/applications/create-education-application/:clientid/:edit" element={<CreateEduApp socket={socket} user={user} />} />
          {/* Clients */}
          <Route exact  path="/clients/view-country-clients/:country" element={<CountryClients />} />
          <Route exact  path="/clients/view-clients" element={<ViewClients socket={socket} user={user} />} />
          <Route exact  path="/external-clients/:from" element={<ExternalClients socket={socket} user={user} />} />
          <Route exact  path="/clients/assign-clients" element={<AssignClients socket={socket} user={user} />} />
          <Route exact  path="/clients/add-client" element={<AddClient socket={socket} user={user} />} />
          <Route exact  path="/clients/my-clients" element={<MyClients socket={socket} user={user} />} />
          <Route exact  path="/clients/user-clients/:clientid" element={<UserClients />} />
          <Route exact  path="/clients/view-leads" element={<ViewLeads />} />
          <Route exact  path="/clients/reports" element={<Reports />} />
          <Route exact  path="/clients/view-client-detail/:id/" element={<ViewClientDetail socket={socket} user={user} />} />
          <Route exact  path="/clients/client-profile/:id/" element={<ViewClientProfile />} />
          {/* Counseling */}
          <Route exact  path="/counseling" element={<Counseling />} />
           {/* Providers */}
          <Route exact  path="/providers/settings" element={<ProviderSetting />} />
          <Route exact  path="/providers/view-providers" element={<ViewProviders />} />
          <Route exact  path="/providers/add-provider" element={<AddProvider />} />
          <Route exact  path="/providers/edit-provider/:providerid" element={<EditProvider />} />
          <Route exact  path="/providers/provider-details/:providerid/" element={<ProviderDetail socket={socket} user={user} />} />
          <Route exact  path="/providers/add-course/:providerid/:providername/:category/:providercountry" element={<AddCourse />} />
          <Route exact  path="/providers/edit-course/:providerid/:courseid/:providername/:category/:providercountry" element={<EditCourse />} />
          <Route exact  path="/providers/view-intakes" element={<ViewIntakes />} />
          <Route exact  path="/providers/view-levels" element={<ViewLevels />} />
          {/* Checklist */}
          <Route exact path="/clients/send-checklist/:clientid/:clientemail/:clientname/:sendername" element={<SendCheckList socket={socket} user={user} />} />
          <Route exact path="/checklist/main-checklist" element={<MainChecklist  />} />
          <Route exact path="/checklist/view-checklists" element={<ViewChecklists />} />
          <Route exact path="/checklist/view-pre-checklists" element={<ViewPreChecklists/>} />
          <Route exact path="/checklist/add-pre-checklists" element={<AddPreCheckList />} />
          <Route exact path="/checklist/view-pre-checklists-details/:pid/:pidname/:cid/:cidname/:migname" element={<ViewPreChecklistDetails />} />
          <Route exact path="/checklist/add-checklist-category/:edit" element={<AddChecklistCategory />} />
          {/* Finance */}
          <Route exact path="/finance/professtional-cost" element={<ViewCost />} />
          <Route exact path="/finance/view-commissions" element={<ViewCommissions />} />
          {/* Invoice */}
          <Route exact path="/invoice/edit-invoice/:invoiceid" element={<EditInvoice />} />
          {/* Expo 2022 */}
          <Route exact path="/expo2022/study-aborad-aug-sep" element={<ExpoAugSep />} />
          <Route exact path="/events/main" element={<MainEvent />} />
          <Route exact path="/events/expo-detail/:id" element={<ExpoDetail />} />
          <Route exact path="/events/expo-consultant-list" element={<ConsultantList />} />
          <Route exact path="/events/create-event" element={<CreateEvent />} />
          <Route exact path="/events/followup/:type" element={<ExpoFollowup />} />
          {/* HRManagement */}
          <Route exact path="/attendence" element={<Attendence />} />
          <Route exact path="/attendence/detail/:userid" element={<AttendenceDetail />} />
          <Route exact path="/attendence/edit/:editid" element={<EditAttendence />} />
          {/* Leave */}
          <Route exact path="/leave" element={<ApplyLeave />} />
          <Route exact path="/manage-leaves" element={<ManageLeaves />} />
           {/* Alerts */}
           <Route exact path="/add-alert" element={<AddAlerts />} />
           <Route exact path="/create-alerts" element={<CreateAlerts socket={socket} user={user} />} />
           <Route exact path="/alert-detail/:id" element={<AlertDetail/>} />
           {/* Chat */}
           <Route exact path="/chat" element={<HandleChat activeUsers={activeUsers} socket={socket} user={user} />} />
            {/* Inbox */}
           <Route exact path="/handle-inbox" element={<HandleInbox activeUsers={activeUsers} socket={socket} user={user} />} />
           {/* Branches */}
           <Route exact path="/manageBranch" element={<ManageBranch socket={socket} user={user} />} />
           <Route exact path="/external-apps/:type" element={<ExternalApps socket={socket} user={user} />} />
            {/* Daily Task */}
            <Route exact path="/task-report" element={<DailyTask />} />
            <Route exact path="/grant-permissions" element={<GrantRolePermission />} />
            <Route exact path="/all-reports" element={<AllDailyReport />} />
            <Route exact path="/user-reports/:id" element={<UserReportHistory />} />
            <Route exact path="/view-report/:id" element={<ViewReport />} />
            <Route exact path="/view-internal-notes" element={<ViewInternalNotes />} />
            <Route exact path="/internal-note-detail/:noteid" element={<InternalNoteDetail />} />
          </Route>
          </Routes>
          </Suspense>
          </div>
          </div>
      </>
      {localStorage.getItem('token') ? <Footer />:''}
       {/* Preview Invoice */}
    </div>
  );
}
function PageNotFound(){
  const params = useParams();
  return <p className='w-100 text-center h-100 my-5 bold size-16'>
  "{params.pageName}" Ooops! Page not found</p> 
}

export default App;

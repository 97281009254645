import ApiHandler from "../ApiManager/ApiHandler";

export const createInternalNotes = async (userData) => {
    const response = await ApiHandler.post('/createInternalNotes', userData);
    if (response.success) {
      return { data: response.data, error: null, message: response.data.message };
    } else {
      return { data: null, error: response.message || 'An error occurred' };
    }
};

export const getInternalNotes = async ({ currentPage = 1, perPage = 10 } = {}) => {
  const response = await ApiHandler.get(`/getInternalNotes?page=${currentPage}&perPage=${perPage}`);
  if (response.success) {
    return { data: response.data, error: null, message: response.data.message };
  } else {
    return { data: null, error: response.message || 'An error occurred' };
  }
};

export const internalNoteDetail = async (noteid) => {
  const response = await ApiHandler.get(`/getInternalNoteDetail/${noteid}`);
  if (response.success) {
    return { data: response.data, error: null, message: response.data.message };
  } else {
    return { data: null, error: response.message || 'An error occurred' };
  }
};
export const internalNoteByUser = async (id) => {
  const response = await ApiHandler.get(`/getInternalNotesByUser/${id}`);
  if (response.success) {
    return { data: response.data, error: null, message: response.data.message };
  } else {
    return { data: null, error: response.message || 'An error occurred' };
  }
};
export const getInboxUnSeen = async () => {
  const response = await ApiHandler.get(`/get-inbox-unseen`);
  if (response.success) {
    return { data: response.data, error: null, message: response.data.message };
  } else {
    return { data: null, error: response.message || 'An error occurred' };
  }
};
export const getMarkAsSeen = async () => {
  const response = await ApiHandler.get(`/mark-as-seen`);
  if (response.success) {
    return { data: response.data, error: null, message: response.data.message };
  } else {
    return { data: null, error: response.message || 'An error occurred' };
  }
};
export const getChatNotifications = async () => {
  const response = await ApiHandler.get(`/getChatNotification`);
  if (response.success) {
    return { data: response.data, error: null, message: response.data.message };
  } else {
    return { data: null, error: response.message || 'An error occurred' };
  }
};
export const getUnSeenAlert = async (id) => {
  const response = await ApiHandler.get(`/get-un-seen-alert/{id}`);
  if (response.success) {
    return { data: response.data, error: null, message: response.data.message };
  } else {
    return { data: null, error: response.message || 'An error occurred' };
  }
};

export const getUnSeen = async () => {
  const response = await ApiHandler.get(`/get-un-seen`);
  if (response.success) {
    return { data: response.data, error: null, message: response.data.message };
  } else {
    return { data: null, error: response.message || 'An error occurred' };
  }
};


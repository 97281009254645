

import React, { Component } from 'react';
import { Button, Modal} from "react-bootstrap";
import swal from 'sweetalert';
import smallSpinner from '../../assets/small.gif';
import Select from 'react-select';
import { view_ApiManager } from '../ApiManager/ApiManager';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../GenericComponents/search.css';
import { createInternalNotes } from '../Services/apiServices';

class InternalMeetingModel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            agenda:'',
            participants:[],
            counselors:[],
            editorState: EditorState.createEmpty(),
            editorHtml: '',
            meetingDate: '',
            startTime: '',
            endTime: '',
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleEditorChange = this.handleEditorChange.bind(this);
    }

    componentDidMount() {
        this.getAllUsers();
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit = async () => {
        const data ={
            counselors: this.state.counselors,
            agenda: this.state.agenda,
            meetingDate: this.state.meetingDate,
            startTime: this.state.startTime,
            endTime: this.state.endTime,
            discussionNotes: this.state.editorHtml,
        }
        console.log(data)
        let result = await createInternalNotes({data});
        console.log('internal-notes', result)
        if (result.error) {
            swal({ text: result.error, icon: "warning", dangerMode: true });
        } else {
            swal("Success!", result.message , "success"); 
            this.props.parentCallback(); 
        }
    }

    handleModal(){
        this.props.parentCallback(); 
    } 

    handleSelectChange(selectedOptions) {
        this.setState({
          counselors: selectedOptions || []
        });
    }

    getAllUsers = () => {
        let url = 'getAllUsers';
        view_ApiManager(url, 0, 0,(res) => {
            console.log('Get all Users',res);
            const participants = res.data.data.map(user => ({
                label: user.name, // Adjust the property name based on your API response
                value: user.id,
              }));
            this.setState({participants: participants})
        },(err) => {
            console.log(err);
        });
     }
     handleEditorChange(editorState) {
        this.setState({ editorState });
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const htmlContent = draftToHtml(rawContentState);
        console.log(htmlContent); // This logs the content as HTML
        this.setState({ editorHtml: htmlContent });
      }
    

    render(){ 
        const { editorState } = this.state;
        return(
        <>
        <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered 
         className="email-model" id="email-model" show={this.props.show} onHide={()=>this.handleModal()}>  

         <Modal.Header closeButton>
         <div className="text-center size-18 col-11 white sora-font">Internal Meeting Notes</div>
            </Modal.Header>  
            <Modal.Body className="ml-2 mr-2 mt-1 pb-1 pt-0">
            <form action='' onSubmit={this.handleSubmit}>
            <div className="row my-2">
            <div className="col-12 my-2 size-18">
                <label className="font-f-monst-sans fw-medium mb-0">Meeting Agenda:  </label>
                <input type='text' className="form-control form-note auto" placeholder="Please Enter" 
                name="agenda" onChange={this.handleChange} />
            </div>

            <div className="col-12 mt-2 size-18 my-2">
                <label className="font-f-monst-sans size-18 fw-medium mb-0">Counselors</label>
                <Select
                isMulti
                name="participants"
                options={this.state.participants.filter(option => !this.state.counselors.includes(option))} // Filter out selected values
                className="s-dropdown py-0 shadow-sm size-18 form-note chip-hide auto"
                classNamePrefix="select"
                onChange={this.handleSelectChange}
                placeholder={'Please Select'} // Use dynamic placeholder
                noOptionsMessage={() => 'No options available'}
                value={this.state.counselors} // Manage selected values
                styles={this.customStyles}
                />
            </div>

             {/* Meeting Date */}
             <div className="col-4 my-2 size-18">
                <label className="font-f-monst-sans fw-medium mb-0">Meeting Date: </label>
                <input
                    type="date"
                    className="form-control form-note"
                    name="meetingDate"
                    onChange={this.handleChange}
                />
            </div>

            <div className="col-3 ms-4 my-2 size-18">
                <label className="font-f-monst-sans fw-medium mb-0">Meeting Start Time</label>
                <input
                    type="time"
                    className="form-control form-note"
                    name="startTime"
                    onChange={this.handleChange}
                />
            </div>

            <div className="col-3 ms-4 my-2 size-18">
                <label className="font-f-monst-sans fw-medium mb-0">Meeting End Time</label>
                <input
                    type="time"
                    className="form-control form-note"
                    name="endTime"
                    value={this.state.endTime}
                    min={this.state.startTime}
                    onChange={this.handleChange}
                />
            </div>

            <div className="col-12 mt-2 size-18 my-2">
            <label className="font-f-monst-sans size-18 fw-medium mb-0">Discussion</label>
            <Editor
                editorState={editorState}
                onEditorStateChange={this.handleEditorChange}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                />
            </div>
            </div>

            </form>
            </Modal.Body>  
            <Modal.Footer className='my-3'>  
            {this.state.agenda && this.state.agenda.length > 0 && this.state.editorHtml && this.state.editorHtml.trim() !== '' && (
            <Button className="btn btn-success col-9" onClick={() => this.handleSubmit()}> Save Meeting Notes </Button>) }
            <Button className="btn btn-danger col-2" onClick={() => this.handleModal()}> Cancel </Button>
            </Modal.Footer>  
         </Modal>
            </>
        )
    }
}
export default InternalMeetingModel

import React ,{useState,useEffect, useRef}from "react"
import Title from '../GenericComponents/Title'
import { useParams } from "react-router";
import swal from 'sweetalert';
import { internalNoteDetail } from "../Services/apiServices"
import './internalNote.css'
import moment from "moment";
import DOMPurify from 'dompurify'

const InternalNoteDetail = (props) => {
    const { noteid } = useParams();
    const [details, setDetails] = useState([]);
    
    useEffect( () => {
        internalNotes();
    }, []);

    const internalNotes = async () => {
        let result = await internalNoteDetail(noteid);
        console.log('internal-notes', result)
        if (result.error) {
            swal({ text: result.error, icon: "warning", dangerMode: true });
        } else {
            setDetails(result.data.data);
        }
    }

    return(
        <>
        <Title back={true} />
        <div className="row w-100 px-3 mb-5">
        <div>
            <div className="border row bg-white">
            <div className="row w-100">
            <div className="m-note row p-3 align-items-center ">
                <h1 className="text-center font-monst white w-100">Meeting Note</h1>
                <h3 className="w-100 text-center bold size-18 font-monst " style={{color:'#88ffd7'}}>
                ({details && moment(details['created_at']).format('D MMM YYYY')})</h3>
            </div>
          
            <div className="row font-f-sans" style={{ border: '4px solid rgb(5 168 185)' }}>
            {/* <h2 className="p-2 w-100 text-center bold">{details && moment(details['created_at']).format('D MMM YYYY')}</h2> */}
            <div className="col-2" style={{ borderLeft: '4px solid rgb(153 229 144)', borderRight:'4px solid #3498db'}}>
            <h2 className="p-4 size-18 bold">AGENDA</h2></div>
            <div className="col-10 p-4" ><p className="size-16">{details['agenda']}</p></div>

            <div className="col-2" style={{ borderLeft: '4px solid rgb(153 229 144)', borderRight:'4px solid #3498db', borderTop: '2px solid #3498db'}}>
            <h2 className="p-4 size-18 bold">Managed By</h2></div>
            <div className="col-10" style={{borderTop: '2px solid #3498db', background:'aliceblue'}}><p className="size-16 p-4">{details['username']}</p></div>

            <div className="col-2" style={{ borderLeft: '4px solid rgb(153 229 144)', borderRight:'4px solid #3498db', borderTop: '2px solid #3498db'}}>
            <h2 className="p-4 size-18 bold">Participants</h2></div>
            <div className="col-10" style={{borderTop: '2px solid #3498db'}}><p className="size-16 p-4">{details['participants'] && details['participants'].map((v, index) => (
                <React.Fragment key={index}>
                {v}
                <br />
                </React.Fragment>
            ))}</p></div>

            <div className="col-2" style={{ borderLeft: '4px solid rgb(153 229 144)', borderRight:'4px solid #3498db',borderTop: '2px solid #3498db'}}>
            <h2 className="p-3 px-4 size-18 bold">Date & Time</h2></div>
            <div className="col-10" style={{borderTop: '2px solid #3498db'}}><p className="size-16 p-3 px-4">{moment(details['meeting_date']).format('D MMM YYYY')} {details['start_time']} - {details['end_time']}</p></div>

            <div className="col-2" style={{ borderLeft: '4px solid rgb(153 229 144)', borderRight:'4px solid #3498db', borderTop: '2px solid #3498db'}}>
            <h2 className="p-4 size-18 bold">Discussion</h2></div>
            <div className="col-10" style={{borderTop: '2px solid #3498db'}}>
            <p style={{whiteSpace: 'pre-wrap'}} className="size-16 p-4" 
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(details['discussion_notes'])}}></p></div>


            </div>
            </div>
            </div>
        </div>
       
        </div>
        </>
    )
}
export default InternalNoteDetail